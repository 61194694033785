<template>
    <div id="ratings">

        <h3 class="title is-3 is-bold">Rezensionen & Rückmeldungen</h3>
        <div class="columns is-multiline">
            <div class="column is-6" v-for="rating in ratings" :key="rating.author">
                <div class="notification has-text-centered">
                    <p>{{rating.content}}</p>
                    <strong><i>{{rating.author}}</i></strong>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data: function () {
            return {
                ratings: [{
                    content: 'Wir sind begeistert und haben riesigen Spass mit den Dominosteinen. Auch unsere Grosskinder freuen sich immer sehr an den bunten Steinen.',
                    author: 'Maria H.'
                },
                {
                    content: 'Die Steine gefallen meinen Schüern sehr gut. Meine Beobachtung als Lehrerin: Sie bevorzugen alle knallig leuchtenden Farben.',
                    author: 'Franziska B.'
                },
                {
                    content: 'Super, tolle Idee schön gemacht. Prima Service. Sehr zu empfehlen. Bravo!',
                    author: 'Claudia H.'
                },
                {
                    content: 'Die Dominosteine machen sehr viel Freude. Es lässt sich mit ihnen viel einfacher bauen als mit den zuvor gekauften, kleineren Holzsteinen.',
                    author: 'Leon P.'
                },
                {
                    content: 'Sehr gut! Bereiten meinen Kindern sehr viel Spass.',
                    author: 'Susanne A.'
                }]
            }
        }
    }
</script>