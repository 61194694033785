<template>
  <div>
    <div class="level">
      <div class="level-left">
        <h3 class="title is-3 is-bold">Artikelübersicht</h3>
      </div>
      <div class="level-right">
        <ShoppingCartWidget />
      </div>
    </div>
    <hr />

    <div v-if="products">
      <!-- Course category highlighted -->
      <div v-if="course_products.length > 0 && is_workshop_highlighted">
        <h3 class="title is-4">Workshops</h3>
        <div class="columns is-multiline">
          <div
            class="column is-3"
            v-for="product in course_products"
            v-bind:key="product.id"
          >
            <ProductCard :product="product" />
          </div>
        </div>
        <hr />
      </div>

      <!-- Domino category -->
      <div v-if="domino_products.length > 0">
        <h3 class="title is-4">Bunte Dominosteine</h3>
        <div class="columns is-multiline is-centered">
          <div
            class="column is-3"
            v-for="product in domino_products"
            v-bind:key="product.id"
          >
            <ProductCard :product="product" />
          </div>
        </div>
        <hr />
      </div>
      <!-- Domino category -->
      <div v-if="tool_products.length > 0">
        <h3 class="title is-4">Hilfsmittel</h3>
        <div class="columns is-multiline">
          <div
            class="column is-3"
            v-for="product in tool_products"
            v-bind:key="product.id"
          >
            <ProductCard :product="product" />
          </div>
        </div>
        <hr />
      </div>
      <!-- Course category -->
      <div v-if="course_products.length > 0 && !is_workshop_highlighted">
        <h3 class="title is-4">Workshops</h3>
        <div class="columns is-multiline">
          <div
            class="column is-3"
            v-for="product in course_products"
            v-bind:key="product.id"
          >
            <ProductCard :product="product" />
          </div>
        </div>
        <hr />
      </div>
    </div>
    <div v-else>
      <div v-for="row of 3" :key="row">
        <h3 class="title is-4">
          <b-skeleton width="200px" height="40px" :animated="true" />
        </h3>
        <div class="columns">
          <div class="column is-3" v-for="index of 4" :key="index">
            <b-skeleton width="100%" height="350px" :animated="true" />
          </div>
        </div>
        <hr />
      </div>
      <b-loading
        :is-full-page="false"
        v-model="is_loading"
        :can-cancel="false"
      ></b-loading>
    </div>
  </div>
</template>
<script>
import { loadProducts } from "@/webapi.js";
import ShoppingCartWidget from "./ShoppingCartWidget";
import ProductCard from "./ProductCard";
export default {
  data: function () {
    return {
      products: null,
      is_loading: false,
    };
  },
  created: function () {
    this.is_loading = true;
    loadProducts().then((resp) => {
      this.is_loading = false;
      this.products = resp.data;
    });
  },
  methods: {
    filterProducts(type) {
      return this.products
        ? this.products.filter((product) => product.type == type)
        : [];
    },
  },
  computed: {
    domino_products() {
      return this.filterProducts("dominoes").sort((a, b) => Number(a.amount) > Number(b.amount) ? 1 : -1);
    },
    tool_products() {
      return this.filterProducts("tool");
    },
    course_products() {
      return this.filterProducts("course").sort((a,b) => a.hours - b.hours);
    },
    is_workshop_highlighted() {
      let hash = this.$route.hash
      if (hash) {
        return '#workshops' == hash
      }
      return false
    }
  },
  components: { ShoppingCartWidget, ProductCard },
};
</script>