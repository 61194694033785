<template>
  <div>
    <div class="card">
      <div class="card-image">
        <figure class="image">
          <img :src="`./img/shop/${product_image}`" :alt="product.name" />
        </figure>
      </div>
      <div class="card-content">
        <div class="content has-text-centered">
          <h4 class="title is-4" style="margin-bottom: 5px">
            {{ product.name }}
          </h4>
          <p v-html="getProductPriceTag(product.price)"></p>
          <div class="buttons is-centered" :disabled="true">
            <router-link
              :to="{ name: 'product', params: { product: product.id } }"
              class="button is-primary"
              v-if="!product.soon"
              >{{goToProductText}}</router-link>
              <button class="button is-primary is-inverted is-focused" v-else>Bald verfügbar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getProductPriceTag(price) {
        switch(this.product.type) {
            case 'dominoes':
            case 'tool':
                return `CHF ${this.formatNumberToTwoDigits(
                    price.product
                )}</p>`;
            case 'course':
                return `ab CHF ${this.formatNumberToTwoDigits(price.product)}`
        }
      
    },
    formatNumberToTwoDigits(number) {
      return Number(number).toFixed(2);
    },
  },
  computed: {
      goToProductText() {
          switch(this.product.type) {
            case 'dominoes':
                return 'Jetzt Farben auswählen'
            case 'course':
                return 'Jetzt buchen'
            default:
                return 'In den Warenkorb'
          }
      },
      product_image() {
        if(this.product.image) {
          return this.product.image
        }
        return this.product.images ? this.product.images[0] : ''
      }
  }
};
</script>