<template>
  <div>
    <ShopBanner />
    <section class="section">
      <div class="container">
        <Notification page_id="shop" :canLoad="true" />
        <Products />
      </div>
    </section>

    <section class="section">
      <div class="container">
        <Ratings />
      </div>
    </section>

  </div>
</template>
<script>
import ShopBanner from './ShopBanner.vue'
  import Products from './Products'
  import Notification from '@/components/Notification'
  import Ratings from './Ratings'
  export default {
    metaInfo: {
      title: 'Tausende Dominosteine für bunte Kettenreaktionen kaufen',
      meta: [{
          name: 'description',
          content: 'Tausende, bunte Dominosteine einfach über unseren Online-Shop bestellen. Dominokettenreaktionen Zuhause mit den besten Dominosteinen aufbauen. Bestellen Sie jetzt mit nur wenigen Klicks.'
        },
        {
          name: 'keywords',
          content: 'Dominosteine kaufen, Spielzeuge für Kinder, Kreatives Spielen, Konzentrationsfördernd, Bunt, Farbig, gute Qualität, Online-Shop'
        }
      ],
    },
    components: {
      ShopBanner,
      Notification,
      Ratings,
      Products
    }
  }
</script>